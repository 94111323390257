import React from "react";
import "font-awesome/css/font-awesome.min.css";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        {/* Company Section */}
        <div>
          <h3 className="text-lg font-bold mb-4">Company</h3>
          <div className="w-12 h-1 bg-pink-500 mb-4 mx-auto md:mx-0"></div>
          <ul className="space-y-2">
            <li>
              <a href="/#home" className="hover:text-pink-500">
                About Us
              </a>
            </li>
            <li>
              <a href="/#services" className="hover:text-pink-500">
                Our Services
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-pink-500">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>

        {/* Empty Space for Centering */}
        <div></div>

        {/* Follow Us Section */}
        <div>
          <h3 className="text-lg font-bold mb-4">Follow Us</h3>
          <div className="w-12 h-1 bg-pink-500 mb-4 mx-auto md:mx-0"></div>
          <div className="flex justify-center md:justify-center space-x-6">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-pink-500 text-2xl"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-pink-500 text-2xl">
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/end2end.homesolution/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-pink-500 text-2xl"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-pink-500 text-2xl">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
