import React from "react";
import { useState, useEffect } from "react";
// import { FaWhatsapp } from "react-icons/fa";
// import { FaWpforms } from "react-icons/fa";
import FormModal from "./FormModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdPhone } from "react-icons/md";
// import ChatBot from "react-chatbotify";
// import InputValidator, {
//   InputValidatorBlock,
// } from "@rcb-plugins/input-validator";

const images = [
  "images/BANNER/Main_1-3-1024x551.jpg",
  "images/BANNER/copernico--8DAN9_oi8g-unsplash.jpg",
  "images/BANNER/cowomen-ZKHksse8tUU-unsplash.jpg",
];

const galleryImages = [
  "images/GALLERY/DISHA CW - GALLERY 9.jpg",
  "images/GALLERY/DISHA CW - GALLERY 3.jpg",
  "images/GALLERY/DISHA CW - GALLERY 8.jpg",
  "images/GALLERY/DISHA CW - GALLERY 7.jpg",
  "images/GALLERY/DISHA CW - GALLERY 6.jpg",
  "images/GALLERY/DISHA CW - GALLERY 4.jpg",
  "images/GALLERY/DISHA CW - GALLERY 5.jpg",
  "images/GALLERY/DISHA CW - GALLERY 2.jpg",
  "images/GALLERY/DISHA CW - GALLERY 1.jpg",
];

const cards = [
  {
    title: "FLEXIBLE SPACE",
    description:
      "Whether you need a desk for a hybrid or remote employees. Move-in ready.",
    buttonText: "JOIN NOW",
  },
  {
    title: "HIGH QUALITY",
    description:
      "Cabins for the teams of any size, or an individual Working-space - we have the solution.",
    buttonText: "JOIN NOW",
  },
  {
    title: "BEST VALUES",
    description:
      "Book by the hour or day, get monthly global access, or settle into an office we have best price for you.",
    buttonText: "JOIN NOW",
  },
];

const aminitiesImages = [
  {
    src: "images/AMENITIES/Cleaning.gif",
    label: "Enhanced cleaning services",
  },
  {
    src: "images/AMENITIES/IT_service.gif",
    label: "IT Services",
  },
  {
    src: "images/AMENITIES/wifi.gif",
    label: "High-speed internet",
  },
  {
    src: "images/AMENITIES/sequirity.gif",
    label: "24/7 security",
  },
  {
    src: "images/AMENITIES/cafetarea.gif",
    label: "Cafeteria",
  },
  {
    src: "images/AMENITIES/aircondition.gif",
    label: "Air conditioning Offices",
  },
];

const BodyContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [modalButtonTitle, setModalButtonTitle] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const totalImages = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
    }, 3000);

    return () => clearInterval(interval);
  }, [totalImages]);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  const handleOpenModal = (title) => {
    setModalButtonTitle(title);
    setOpen(true);
  };

  return (
    <>
      <div>
        <div className="flex flex-col lg:flex-row " id="home">
          <div className="relative w-full lg:w-[70%] h-1/2 lg:h-screen">
            <img
              src={images[currentIndex]}
              alt="slider"
              className="w-full h-full"
            />
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-2xl text-white p-2 rounded-full"
              onClick={prevSlide}
            >
              <img
                src="images/LEFT_ARR-removebg-preview.png"
                alt="Previous"
                className="w-6 h-6" // Adjust the width and height to match the size of the ChevronLeft icon
              />
            </button>
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-2xl text-white p-2 rounded-full"
              onClick={nextSlide}
            >
              <img
                src="images/RIGHT_ARR__2_-removebg-preview.png"
                alt="Previous"
                className="w-6 h-6" // Adjust the width and height to match the size of the ChevronLeft icon
              />
            </button>
          </div>
          <div className="w-full lg:w-[30%] h-auto lg:h-full bg-custom-gradient py-4 flex flex-col items-center border-5 border-#4ca1af sm:border-5 lg:border-none">
            <div className="text-left p-2 my-2">
              {[
                {
                  content: "Disha Co-Working Station,",
                  subContent: "Plot No - 02",
                  animation: "animate-slideInLeft",
                },
                {
                  content: "1604, Kamdhenu Commerz,",
                  subContent: "Sector 14, Kharghar,",
                  animation: "animate-slideInRight",
                },
                {
                  content: "Navi Mumbai,",
                  subContent: "Maharashtra - 410210",
                  animation: "animate-slideInLeft",
                },
              ].map((item, index) => (
                <p
                  key={index}
                  className={`text-sm leading-4 sm:text-base sm:leading-6 md:text-xl md:leading-8 lg:text-2xl lg:leading-10 text-white font-bold ${item.animation} my-2`}
                >
                  {item.content}
                  <br />
                  {item.subContent}
                </p>
              ))}
            </div>

            <div className="relative z-10 flex items-center justify-center space-x-2 text-black bg-[#fff8e1] px-4 py-2 rounded-lg w-full sm:w-4/5 h-[50px] sm:h-[45px] lg:h-[50px] rounded-lg m-2 my-6">
              <MdPhone className="animate-vibrate" />
              <span className="text-sm sm:text-base lg:text-lg">
                +91 93269 59938
              </span>
            </div>

            <button
              className="relative w-full sm:w-4/5 h-[50px] sm:h-[45px] lg:h-[50px] border-none outline-none text-black cursor-pointer z-0 rounded-lg m-2 text-sm sm:text-base lg:text-lg transition duration-300 hover:text-custom-gradient glow-on-hover"
              onClick={() => handleOpenModal("Enquiry")}
            >
              Enquiry
            </button>
          </div>
        </div>

        <div className="bg-[#f7f5dd] w-full text-start" id="services">
          <div className="w-full max-w-7xl mx-auto p-4 text-start">
            {/* Heading */}
            <h1 className="mt-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold uppercase text-[#00A1AB] text-center">
              OUR SERVICES
            </h1>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl font-semibold text-center mt-2">
              CUSTOMISED WORKSPACE AS PER YOUR NEED
            </p>
          </div>

          {/* Cards Section */}
          <div className="flex flex-wrap justify-center sm:justify-around items-stretch bg-[#f7f5dd] p-6 gap-6">
            {cards.map((card, index) => (
              <div
                key={index}
                className="bg-gradient-to-br from-blue-900 to-teal-400 text-white p-6 rounded-2xl shadow-lg w-full sm:w-72 flex flex-col"
              >
                <h2 className="text-lg sm:text-xl font-bold mb-4">
                  {card.title}
                </h2>
                <p className="text-sm sm:text-base mb-6 flex-grow">
                  {card.description}
                </p>
                <button
                  className="mt-auto bg-amber-300 hover:bg-amber-400 text-black py-2 px-4 rounded hover:text-custom-gradient glow-on-hover"
                  onClick={() => handleOpenModal("Enquiry")}
                >
                  {card.buttonText}
                </button>
              </div>
            ))}
          </div>

          {/* Call-to-Action Section */}
          <div className="flex flex-col justify-center items-center mt-6 space-y-4">
            <p className="text-sm sm:text-base md:text-lg lg:text-xl font-semibold text-center">
              Need something different? No problem!
            </p>
            <button
              className="bg-[#00A1AB] hover:bg-[#009199] text-white font-bold py-3 px-6 rounded-lg w-auto sm:w-auto"
              onClick={() => handleOpenModal("Enquiry")}
            >
              GET IN TOUCH
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center px-4 sm:px-6 lg:px-12 mt-6">
          <h2 className="text-3xl lg:text-4xl font-bold text-teal-600 text-center mb-6">
            ABOUT US
          </h2>
          <div className="flex flex-col lg:flex-row items-center justify-between bg-[#f7f5dd] p-6 lg:p-10 gap-8 lg:gap-12 w-full">
            {/* Left Section */}
            <div className="relative w-full lg:w-1/2 flex justify-center items-center">
              {/* Background Circle */}
              <img
                src="images/day-night.gif"
                alt="24/7"
                className="w-80 h-60 sm:w-[30rem] sm:h-[22rem] lg:w-[36rem] lg:h-[26rem] rounded-full"
              />
              {/* Inner Images */}
              <div className="absolute top-[50%] right-[45%] h-[80px] w-[80px] sm:h-[120px] sm:w-[120px] lg:h-[160px] lg:w-[160px] translate-x-[-50%]">
                <img
                  src="images/work.gif"
                  alt="Working Woman"
                  className="w-full rounded shadow-md"
                />
              </div>
              <div className="absolute top-[45%] left-[60%] h-[100px] w-[100px] sm:h-[180px] sm:w-[180px] lg:h-[220px] lg:w-[220px] translate-x-[-50%]">
                <img
                  src="images/team-work.gif"
                  alt="Group Work"
                  className="w-full rounded shadow-md"
                />
              </div>
            </div>

            {/* Right Section */}
            <div className="w-full lg:w-1/2 bg-white rounded-lg shadow-md p-6 lg:px-10 lg:py-8">
              <p className="text-gray-700 leading-6 text-justify text-sm sm:text-base lg:text-lg">
                Disha Co-working Station was established in the year 2020 during
                Corona time. Located at Kamdhenu Commerz in Sector 14, Kharghar
                in Navi Mumbai. Disha Co-Working offers a bouquet of Co-Working
                spaces ranging from Independent Cabins to flexible Work
                Stations. Open 24*7 - Come and Experience the latest in the
                offering.
              </p>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-1 sm:px-8" id="gallery">
          <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-[48px] text-[#00A1AB] font-bold text-center my-8 transition-all duration-300">
            GALLERY
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[1vh] p-[1vh] max-w-full mx-auto bg-custom-gradient justify-center">
            {galleryImages.map((src, index) => (
              <div className="gallery-item group" key={index}>
                <img
                  src={src}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-[300px] object-cover transition-all duration-100 ease-out group-hover:scale-104 group-hover:filter-none"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="featured-listings" id="amenities">
          <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-[48px] font-bold text-center my-8 transition-all duration-300">
            BUILDING AMENITIES
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 lg:max-w-[60%] lg:mx-auto">
            {aminitiesImages.map((item, index) => (
              <div className="aminitiesImage-item" key={index}>
                <img
                  className="w-full h-48 object-cover"
                  src={item.src}
                  alt={`Aminities ${index + 1}`}
                />
                <h2
                  className="text-center m-2"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  {item.label}
                </h2>
              </div>
            ))}
          </div>
        </div>

        <section id="maps">
          <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-[48px] text-[#00A1AB] font-bold text-center my-8 transition-all duration-300">
            LOCATION MAP
          </h1>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7542.882575161722!2d73.073113!3d19.044326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c21103e2885f%3A0x71a1d27dcb311ffb!2sKamdhenu!5e0!3m2!1sen!2sin!4v1732886882951!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Location Map"
          ></iframe>
        </section>

        <FormModal
          open={open}
          onClose={() => setOpen(false)}
          formTitle="Disha Co-Working Space"
          formSubTitle="Register here for Best Offers"
          buttonTitle={modalButtonTitle}
        />
      </div>
    </>
  );
};

export default BodyContent;
